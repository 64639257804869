<template>
  <div>
    <h2 class="mb-5">A Kinetic Study - Catalase: Data & Calculations - Part B</h2>

    <h3 class="mb-4">Data</h3>

    <p class="mb-n1">
      <v-text-field
        v-model="inputs.table1Caption"
        prefix="Table 1. "
        label="Enter table caption here"
      />
    </p>

    <p class="pl-n8 mb-5">
      <v-simple-table>
        <thead>
          <tr>
            <td style="font-weight: bold; text-align: left; width: 36%">Property</td>
            <td style="font-weight: bold; text-align: center; width: 22%">Trial 1</td>
            <td style="font-weight: bold; text-align: center; width: 22%">Trial 2</td>
            <td style="font-weight: bold; text-align: center">Unit</td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>V (catalase)</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vCatalaseBT1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vCatalaseBT2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <v-select v-model="inputs.unitCatalaseB" :items="unitOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>V <chemical-latex content="(H2O2)" /></td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vH2O2BT1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vH2O2BT2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <v-select v-model="inputs.unitH2O2B" :items="unitOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>V <chemical-latex content="(total)" /></td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vTotBT1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vTotBT2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <v-select v-model="inputs.unitVtotB" :items="unitOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td><chemical-latex content="\%~H2O2" /></td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.pctH2O2BT1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.pctH2O2BT2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0" style="text-align: center">
              <stemble-latex content="$\%$" />
            </td>
          </tr>

          <tr>
            <td>Temperature</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.tempBT1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.tempBT2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <v-select v-model="inputs.unitTempB" :items="unitOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>Initial Rate</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.initRateBT1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.initRateBT2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <v-select v-model="inputs.unitInitRateB" :items="unitOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </p>

    <h3 class="mb-4">Calculations</h3>

    <p class="mb-n1">
      <v-text-field
        v-model="inputs.table2Caption"
        prefix="Table 2. "
        label="Enter table caption here"
      />
    </p>

    <p class="pl-n8 mb-5">
      <v-simple-table>
        <thead>
          <tr>
            <td style="font-weight: bold; text-align: left; width: 36%">Property</td>
            <td style="font-weight: bold; text-align: center; width: 22%">Trial 1</td>
            <td style="font-weight: bold; text-align: center; width: 22%">Trial 2</td>
            <td style="font-weight: bold; text-align: center">Unit</td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>Temperature</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.tempET1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.tempET2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <v-select v-model="inputs.unitTempE" :items="unitOptions2" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>Initial Rate</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.initRateBT1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.initRateBT2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <v-select v-model="inputs.unitInitRateB" :items="unitOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>1/T</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.inverseTempET1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.inverseTempET2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <v-select
                v-model="inputs.unitInverseTempE"
                :items="unitOptions2"
                outlined
                hide-details
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>ln(Initial Rate)</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.lnInitRateET1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.lnInitRateET2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0" style="text-align: center">
              <stemble-latex content="$\text{N/A}$" />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </p>

    <p class="mb-3">
      Based on the data collected here, what is the activation energy for the decomposition of
      hydrogen peroxide when catalyzed by catalase?
    </p>

    <calculation-input
      v-model="inputs.activationEnergyE"
      class="mb-5"
      prepend-text="$\text{E}_\text{a}:$"
      append-text="$\text{kJ/mol}$"
      :disabled="!allowEditing"
    />

    <p class="mb-2 mt-2">
      In the space provided below, please show your work for the calculation of the activation
      energy that you reported above.
    </p>

    <p class="mb-n2">
      <s-textarea v-model="inputs.eaCalcWork" outlined />
    </p>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'OttawaLab5ReportSheetPartB',
  components: {
    ChemicalLatex,
    CalculationInput,
    StembleLatex,
    STextarea,
  },
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        table1Caption: null,
        vCatalaseBT1: null,
        vCatalaseBT2: null,
        vH2O2BT1: null,
        vH2O2BT2: null,
        vTotBT1: null,
        vTotBT2: null,
        pctH2O2BT1: null,
        pctH2O2BT2: null,
        tempBT1: null,
        tempBT2: null,
        initRateBT1: null,
        initRateBT2: null,
        unitCatalaseB: null,
        unitH2O2B: null,
        unitVtotB: null,
        unitTempB: null,
        unitInitRateB: null,

        table2Caption: null,
        tempET1: null,
        tempET2: null,
        inverseTempET1: null,
        inverseTempET2: null,
        lnInitRateET1: null,
        lnInitRateET2: null,
        unitTempE: null,
        unitInverseTempE: null,

        activationEnergyE: null,
        eaCalcWork: null,
      },
      unitOptions: [
        '°C',
        'g',
        'g/L',
        'mL/drop',
        'kg',
        'mL',
        'mol/L',
        'kPa/s',
        '°F',
        'g/mL',
        'mol/kg',
        'm/s',
        'min',
        'M/min',
      ],
      unitOptions2: [
        '$\\text{g}^{-1}$',
        'L/g',
        '$^\\circ\\text{R}$',
        '$^\\circ\\text{R}^{-1}$',
        'mol/L',
        'kPa/s',
        '$^\\circ\\text{F}$',
        '$^\\circ\\text{F}^{-1}$',
        '$\\text{K}$',
        '$\\text{K}^{-1}$',
        'm/s',
        'min',
        '$\\text{min}^{-1}$',
        'M/min',
      ],
    };
  },
};
</script>

<style></style>
